import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Chair from '../../src/Icons/Chair';
import MassageChair from '../../src/Icons/MassageChair';
import Sofa from '../../src/Icons/Sofa';
import Futon from '../../src/Icons/Futon';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Icon from 'mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Chair width={30} height={30} />\n  <MassageChair width={30} height={30} />\n  <Sofa width={30} height={30} />\n  <Futon width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Chair,
      MassageChair,
      Sofa,
      Futon,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Chair width={30} height={30} mdxType="Chair" />
    <MassageChair width={30} height={30} mdxType="MassageChair" />
    <Sofa width={30} height={30} mdxType="Sofa" />
    <Futon width={30} height={30} mdxType="Futon" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      